<template>
    <v-card flat>
        <v-card-title>
            <div class="mb-4">
                <span v-if="newSpaceType === spaceTypes.EDUCATION_SPACE" class="primary--text font-weight-bold">New Course</span>
                <span v-else-if="newSpaceType === spaceTypes.RESEARCH_SPACE" class="primary--text font-weight-bold">New Research Project</span>
                <span v-else-if="newSpaceType === spaceTypes.VENDOR_SPACE" class="primary--text font-weight-bold">New Dataset</span>
            </div>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <h4>Name of the space</h4>
                <v-text-field
                    v-model="spaceName"
                    :counter="50"
                    label="Provide a name for your space"
                    :rules="[rules.nonEmpty]"
                    required
                    :disabled="loading || fetchingSpaces"
                    autofocus
                ></v-text-field>
                <ShortIdInput :disabledEdit="loading || fetchingSpaces" :longId="spaceName" @input="updateShortId($event.nextValue)"></ShortIdInput>
                <div class="d-flex align-center mt-10">
                    <h4>Space visibility:</h4>
                    <span class="ml-1"> {{ selectedSpaceInformation.label }}</span>
                    <v-switch v-model="showVisibilityOptions" hide-details class="my-0 ml-5" dense>
                        <template v-slot:label>
                            <span class="caption">More options</span>
                        </template>
                    </v-switch>
                </div>
                <v-radio-group v-if="showVisibilityOptions" :ripple="false" v-model="spaceVisibility">
                    <div v-for="(visibilityType, index) in spaceVisibilityTypes" :key="index">
                        <v-radio class="mt-3 caption" color="secondary" :value="visibilityType.label" :label="visibilityType.label"> </v-radio>
                        <span :class="spaceVisibility === visibilityType.label ? 'caption secondary--text' : 'caption'">{{ visibilityType.description }}</span>
                    </div>
                </v-radio-group>
                <h4 class="mt-10">Description of the space</h4>
                <v-textarea
                    v-model="spaceDescription"
                    label="Provide a description for your space"
                    rows="4"
                    auto-grow
                    :rules="[rules.nonEmpty]"
                    required
                    :disabled="loading || fetchingSpaces"
                ></v-textarea>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <div style="width:100%" class="d-flex flex-column align-center">
                <div class="d-flex align-center">
                    <v-btn
                        class="mr-1"
                        :loading="loading || fetchingSpaces"
                        :disabled="!valid || loading || fetchingSpaces"
                        @click="addSpace()"
                        color="secondary"
                    >
                        <v-icon small>add</v-icon>Add Space
                    </v-btn>
                    <v-btn
                        :to="{ name: 'home-dashboard', params: { oid: $route.params.oid } }"
                        color="secondary"
                        outlined
                        text
                        exact
                        :disabled="loading || fetchingSpaces"
                        >cancel</v-btn
                    >
                </div>
                <v-alert max-width="600" :value="$data.error" color="error" icon="warning" class="mt-4" text>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold">{{ errorContent }}</span>
                        <span>
                            For more information on space creation issues, check the troubleshooting documentation
                            <v-btn
                                class="font-weight-bold"
                                small
                                text
                                color="error"
                                href="https://docs.nuvolos.cloud/troubleshooting/authorization-issues/cannot-create-a-space"
                                target="_blank"
                                >here</v-btn
                            >
                        </span>
                    </div>
                </v-alert>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
const ShortIdInput = () => import('@/components/ShortIdInput')

export default {
    mixins: [enumsData],
    data() {
        return {
            spaceName: '',
            noSpaceName: false,
            showVisibilityOptions: false,
            spaceShortName: '',
            spaceDescription: '',
            noSpaceDescription: false,
            spaceVisibility: 'Private',
            error: false,
            errorContent: 'AN ERROR HAS OCCURRED',
            rules: {
                nonEmpty: p => p.length > 0 || 'Please provide a value.'
            },
            loading: false,
            valid: false,
            spaceVisibilityTypes: [
                {
                    label: 'Private',
                    code: 3,
                    description: 'Only you, the organization managers, and the people you explicitly invite can see the space.'
                },
                {
                    label: 'Faculty only',
                    code: 2,
                    description: 'Only you, the organization managers, the people you explicitly invite, and faculty members can see the space.'
                },
                {
                    label: 'Affiliate only',
                    code: 1,
                    description:
                        'Only you, the organization managers, the people you explicitly invite, faculty members, and the students with university-linked account can see the space.'
                },
                {
                    label: 'Public',
                    code: 0,
                    description: 'Every member of this organization can see the space.'
                }
            ]
        }
    },
    components: {
        ShortIdInput
    },
    props: {
        spaceTypeIcon: String,
        newSpaceType: Number
    },
    methods: {
        updateShortId(newShortId) {
            this.$data.spaceShortName = newShortId
        },
        addSpace: function() {
            this.$data.error = false
            this.$data.noSpaceName = false
            this.$data.noSpaceDescription = false
            const apiURL = '/orgs/' + this.$route.params.oid + '/spaces'
            if (this.$data.spaceName === '') {
                this.$data.noSpaceName = true
            }
            if (!this.$data.spaceDescription) {
                this.$data.noSpaceDescription = true
            }
            if (!this.$data.noSpaceName && !this.$data.noSpaceDescription && this.$data.spaceVisibility) {
                this.$data.loading = true
                const postBody = {
                    description: this.$data.spaceDescription,
                    long_id: this.$data.spaceName,
                    short_id: this.$data.spaceShortName,
                    space_type: this.$props.newSpaceType,
                    visibility_type: this.selectedSpaceInformation.code
                }
                this.$axios
                    .post(apiURL, postBody, { timeout: 300000 })
                    .then(response => {
                        const spaceData = {
                            oid: response.data.oid,
                            sid: response.data.sid
                        }
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Space created successfully',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'check_circle'
                        })
                        this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid).finally(() => {
                            if (this.createSpaceAndApp.operationSelected) {
                                this.$axios.get(`spaces/${response.data.sid}/master/development`).then(response => {
                                    const development = response.data
                                    this.$store.dispatch('homeStore/updateCreateSpaceAndApp', { stepVal: 2 })
                                    this.$router.push({
                                        name: 'snapshot-applications-add',
                                        params: {
                                            oid: spaceData.oid,
                                            sid: spaceData.sid,
                                            iid: development.iid,
                                            snid: development.snid
                                        }
                                    })
                                })
                            }
                        })
                    })
                    .catch(error => {
                        this.$data.error = true
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.reason &&
                            error.response.data.reason[0].includes('duplicate key value violates unique constraint "space_uc_oid_short_id"')
                        ) {
                            this.$data.errorContent = 'The short name you chose for this space is used by another space, please choose another name'
                        }
                    })
                    .finally(() => {
                        this.$data.loading = false
                    })
            }
        }
    },
    computed: {
        ...mapState('orgStore', ['fetchingSpaces']),
        ...mapState('homeStore', ['createSpaceAndApp']),
        selectedSpaceInformation() {
            const spaceVisibilityData = this.spaceVisibilityTypes.find(item => item.label === this.spaceVisibility)
            return spaceVisibilityData
        }
    },
    mounted() {
        if (this.createSpaceAndApp.operationSelected !== true) {
            this.$store.dispatch('homeStore/updateCreateSpaceAndApp', { operationSelectedVal: true, stepVal: 1 })
        }
    },
    beforeDestroy() {
        if (this.createSpaceAndApp.operationSelected === true && this.createSpaceAndApp.step !== 2) {
            this.$store.dispatch('homeStore/resetCreateSpaceAndApp')
        }
    }
}
</script>
