var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("div", { staticClass: "mb-4" }, [
          _vm.newSpaceType === _vm.spaceTypes.EDUCATION_SPACE
            ? _c("span", { staticClass: "primary--text font-weight-bold" }, [
                _vm._v("New Course")
              ])
            : _vm.newSpaceType === _vm.spaceTypes.RESEARCH_SPACE
            ? _c("span", { staticClass: "primary--text font-weight-bold" }, [
                _vm._v("New Research Project")
              ])
            : _vm.newSpaceType === _vm.spaceTypes.VENDOR_SPACE
            ? _c("span", { staticClass: "primary--text font-weight-bold" }, [
                _vm._v("New Dataset")
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("h4", [_vm._v("Name of the space")]),
              _c("v-text-field", {
                attrs: {
                  counter: 50,
                  label: "Provide a name for your space",
                  rules: [_vm.rules.nonEmpty],
                  required: "",
                  disabled: _vm.loading || _vm.fetchingSpaces,
                  autofocus: ""
                },
                model: {
                  value: _vm.spaceName,
                  callback: function($$v) {
                    _vm.spaceName = $$v
                  },
                  expression: "spaceName"
                }
              }),
              _c("ShortIdInput", {
                attrs: {
                  disabledEdit: _vm.loading || _vm.fetchingSpaces,
                  longId: _vm.spaceName
                },
                on: {
                  input: function($event) {
                    return _vm.updateShortId($event.nextValue)
                  }
                }
              }),
              _c(
                "div",
                { staticClass: "d-flex align-center mt-10" },
                [
                  _c("h4", [_vm._v("Space visibility:")]),
                  _c("span", { staticClass: "ml-1" }, [
                    _vm._v(" " + _vm._s(_vm.selectedSpaceInformation.label))
                  ]),
                  _c("v-switch", {
                    staticClass: "my-0 ml-5",
                    attrs: { "hide-details": "", dense: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "caption" }, [
                              _vm._v("More options")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.showVisibilityOptions,
                      callback: function($$v) {
                        _vm.showVisibilityOptions = $$v
                      },
                      expression: "showVisibilityOptions"
                    }
                  })
                ],
                1
              ),
              _vm.showVisibilityOptions
                ? _c(
                    "v-radio-group",
                    {
                      attrs: { ripple: false },
                      model: {
                        value: _vm.spaceVisibility,
                        callback: function($$v) {
                          _vm.spaceVisibility = $$v
                        },
                        expression: "spaceVisibility"
                      }
                    },
                    _vm._l(_vm.spaceVisibilityTypes, function(
                      visibilityType,
                      index
                    ) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c("v-radio", {
                            staticClass: "mt-3 caption",
                            attrs: {
                              color: "secondary",
                              value: visibilityType.label,
                              label: visibilityType.label
                            }
                          }),
                          _c(
                            "span",
                            {
                              class:
                                _vm.spaceVisibility === visibilityType.label
                                  ? "caption secondary--text"
                                  : "caption"
                            },
                            [_vm._v(_vm._s(visibilityType.description))]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c("h4", { staticClass: "mt-10" }, [
                _vm._v("Description of the space")
              ]),
              _c("v-textarea", {
                attrs: {
                  label: "Provide a description for your space",
                  rows: "4",
                  "auto-grow": "",
                  rules: [_vm.rules.nonEmpty],
                  required: "",
                  disabled: _vm.loading || _vm.fetchingSpaces
                },
                model: {
                  value: _vm.spaceDescription,
                  callback: function($$v) {
                    _vm.spaceDescription = $$v
                  },
                  expression: "spaceDescription"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-actions", [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column align-center",
            staticStyle: { width: "100%" }
          },
          [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      loading: _vm.loading || _vm.fetchingSpaces,
                      disabled: !_vm.valid || _vm.loading || _vm.fetchingSpaces,
                      color: "secondary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.addSpace()
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                    _vm._v("Add Space ")
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      to: {
                        name: "home-dashboard",
                        params: { oid: _vm.$route.params.oid }
                      },
                      color: "secondary",
                      outlined: "",
                      text: "",
                      exact: "",
                      disabled: _vm.loading || _vm.fetchingSpaces
                    }
                  },
                  [_vm._v("cancel")]
                )
              ],
              1
            ),
            _c(
              "v-alert",
              {
                staticClass: "mt-4",
                attrs: {
                  "max-width": "600",
                  value: _vm.$data.error,
                  color: "error",
                  icon: "warning",
                  text: ""
                }
              },
              [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.errorContent))
                  ]),
                  _c(
                    "span",
                    [
                      _vm._v(
                        " For more information on space creation issues, check the troubleshooting documentation "
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            small: "",
                            text: "",
                            color: "error",
                            href:
                              "https://docs.nuvolos.cloud/troubleshooting/authorization-issues/cannot-create-a-space",
                            target: "_blank"
                          }
                        },
                        [_vm._v("here")]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }